import cn from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useRef, useLayoutEffect } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Plus } from '@sravni/react-icons';

import { FULL_DEAL_ENTRY_POINT_TEXTS } from '@src/constants/fullDeal';
import { formatMoney } from '@src/helpers/formatMoney';
import { useWindowSize } from '@src/hooks/useWindowSize';

import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
    buttonLabel: string;
    amount: number;
    hasOffer: boolean;
    hasForm: boolean;
    isOfferWaiting: boolean;
    hasIssuedMicrocreditDeals: boolean;
    onButtonClick: (event: SyntheticEvent) => void;
};

export const StickyBlock: FC<Props> = ({
    buttonLabel,
    amount,
    hasOffer,
    hasForm,
    isOfferWaiting,
    hasIssuedMicrocreditDeals,
    onButtonClick,
}) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize({});

    useLayoutEffect(() => {
        const isBlockMoreThanWindow = rootRef.current && rootRef.current.offsetWidth > width;

        if (isBlockMoreThanWindow) {
            rootRef.current.className = cn(styles.stickyWrapper, {
                [styles.hiddenStickyWrapper]: isBlockMoreThanWindow,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isBlockMoreThanWindow = rootRef.current && rootRef.current.offsetWidth > width;
    const hasOfferOrForm = hasOffer || hasForm || isOfferWaiting;

    return (
        <div
            className={cn(styles.stickyWrapper, {
                [styles.hiddenStickyWrapper]: isBlockMoreThanWindow,
            })}
            ref={rootRef}
        >
            <div className={styles.content}>
                <Text className="h-color-L100" size={14}>
                    {getTitle(hasOffer, hasForm, isOfferWaiting)}
                </Text>
                <Text className="h-color-D15" size={12}>
                    {hasOfferOrForm ? formatMoney(amount, { symbol: 'руб' }) : 'Заполните одну анкету'}
                </Text>
            </div>
            <Button className={styles.button} size={36} block variant="primary" color="blue" onClick={onButtonClick}>
                {hasIssuedMicrocreditDeals && !hasOfferOrForm && <Icon size={20} strong icon={<Plus />} />}
                {buttonLabel}
            </Button>
        </div>
    );
};

function getTitle(hasOffer: boolean, hasForm: boolean, isOfferWaiting: boolean) {
    if (!hasOffer && !hasForm && !isOfferWaiting) {
        return FULL_DEAL_ENTRY_POINT_TEXTS.heading;
    }

    if (hasOffer) {
        return FULL_DEAL_ENTRY_POINT_TEXTS.stickyBlockHasOfferTitle;
    }

    if (hasForm || isOfferWaiting) {
        return FULL_DEAL_ENTRY_POINT_TEXTS.stickyBlockHasFormTitle;
    }
}
