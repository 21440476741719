import type { FormEvent } from 'react';
import { useState } from 'react';

import { Cookie } from '@src/modules/cookie';

import { useSaveUserPhone } from './useSaveUserPhone';
import { validatePhoneNumber } from './validatePhoneNumber';

interface Props {
    phoneNumberValidate(phone: string | number): boolean;
}

export const usePhoneNumberForm = ({ phoneNumberValidate }: Props) => {
    const [phone, setPhone] = useState(() => Cookie.get()._PN_ ?? '');
    const [errorMessage, setErrorMessage] = useState('');

    const { isSubmitDisabled, onSave } = useSaveUserPhone({ setErrorMessage });

    const onChangePhoneNumber = (event: FormEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value.replace(/[^0-9]/g, '').replace(/^7/, '+7');

        setPhone(newValue);
        setErrorMessage('');
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (isSubmitDisabled) {
            return;
        }

        const { isValid, errorMessage } = validatePhoneNumber(phone, phoneNumberValidate);

        if (!isValid) {
            setErrorMessage(errorMessage);
            return;
        }

        onSave(phone);
    };

    return {
        phone,
        errorMessage,
        isSubmitDisabled,
        onChangePhoneNumber,
        onSubmit,
    };
};
