import cn from 'classnames';
import type { SyntheticEvent } from 'react';
import React, { memo, useState } from 'react';

import { MaskInput, Typography } from '@sravni/react-design-system';
import type { MaskInputProps } from '@sravni/react-design-system/dist/types/components/MaskInput';

import styles from './PhoneInput.module.scss';

const PHONE_INPUT_LABEL = 'Введите номер телефона';

interface Props extends Omit<MaskInputProps, 'inputMode'> {
    errorMessage?: string;
    containerClassName?: string;
}

export const PhoneInput = memo(
    ({
        errorMessage,
        containerClassName,
        invalid,
        onFocus,
        onBlur,
        label = PHONE_INPUT_LABEL,
        ...inputProps
    }: Props) => {
        const [focus, setFocus] = useState(false);

        const handleFocus = (e: SyntheticEvent) => {
            setFocus(true);
            onFocus?.(e);
        };

        const handleBlur = (e: SyntheticEvent) => {
            setFocus(false);
            onBlur?.(e);
        };

        return (
            <div className={cn(styles.container, containerClassName)}>
                <MaskInput
                    inputMode="tel"
                    label={label}
                    {...inputProps}
                    invalid={!focus && invalid}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />

                {Boolean(errorMessage) && !focus && (
                    <Typography.Text className="h-color-R100" size={12}>
                        {errorMessage}
                    </Typography.Text>
                )}
            </div>
        );
    },
);

PhoneInput.displayName = 'PhoneInput';
