import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export const useVisibility = <T extends Element>(ref: RefObject<T>, threshold = 0.2): boolean => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const currentElement = ref.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsVisible(entry.isIntersecting);
                });
            },
            { threshold },
        );

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [ref, threshold]);

    return isVisible;
};
