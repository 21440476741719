import type { AxiosRequestConfig } from 'axios';

export const AXIOS_CONFIG: AxiosRequestConfig = {
    timeout: 15000,
    responseType: 'json',
    headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Requested-With': 'XMLHttpRequest',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
};
