import type { FC } from 'react';
import React from 'react';

import { Button } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import { DESKTOP_BUTTON_SIZE, MOBILE_BUTTON_SIZE } from '@src/components/PageHeader/consts';
import { PhoneInput, validateRussianPhoneNumber } from 'shared/ui';

import { usePhoneNumberForm } from '../../lib';

import { INPUT_MASK } from './PhoneNumberCheckerForm.config';
import styles from './PhoneNumberCheckerForm.module.scss';
import { phoneNumberCheckerFormTexts } from './PhoneNumberCheckerForm.text';

interface Props {
    phoneNumberValidate?(phone: string | number): boolean;
}

export const PhoneNumberCheckerForm: FC<Props> = React.memo(({ phoneNumberValidate }) => {
    const isMobile = useIsMobile();

    const { phone, errorMessage, isSubmitDisabled, onChangePhoneNumber, onSubmit } = usePhoneNumberForm({
        phoneNumberValidate,
    });

    return (
        <form className={styles.wrapper} onSubmit={onSubmit}>
            <div className={styles.phoneInput}>
                <PhoneInput
                    onChange={onChangePhoneNumber}
                    value={phone}
                    mask={INPUT_MASK}
                    invalid={Boolean(errorMessage)}
                    errorMessage={errorMessage}
                />
            </div>

            <Button
                variant="primary"
                size={isMobile ? MOBILE_BUTTON_SIZE : DESKTOP_BUTTON_SIZE}
                className={styles.button}
                disabled={isSubmitDisabled}
                type="submit"
            >
                {phoneNumberCheckerFormTexts.submitBtn}
            </Button>
        </form>
    );
});

PhoneNumberCheckerForm.displayName = 'PhoneNumberCheckerForm';

PhoneNumberCheckerForm.defaultProps = {
    phoneNumberValidate: validateRussianPhoneNumber,
};
