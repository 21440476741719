export type InputBadge = {
    label: string;
    value: number;
};

export const AMOUNT_BADGE_LIST: InputBadge[] = [
    {
        label: '3 000',
        value: 3000,
    },
    {
        label: '5 000',
        value: 5000,
    },
    {
        label: '10 000',
        value: 10000,
    },
    {
        label: '12 000',
        value: 12000,
    },
    {
        label: '15 000',
        value: 15000,
    },
    {
        label: '30 000',
        value: 30000,
    },
    {
        label: '60 000',
        value: 60000,
    },
];

export const TERM_BADGE_LIST: InputBadge[] = [
    {
        label: '5 дней',
        value: 5,
    },
    {
        label: '10 дней',
        value: 10,
    },
    {
        label: '15 дней',
        value: 15,
    },
    {
        label: '30 дней',
        value: 30,
    },
    {
        label: '60 дней',
        value: 60,
    },
    {
        label: '90 дней',
        value: 90,
    },
    {
        label: '110 дней',
        value: 110,
    },
];
