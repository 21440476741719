import { useCallback, useState } from 'react';

import { useBoolean } from '@sravni/react-utils';

import { Currency } from '@src/@types/currency';
import { HeaderControl } from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading/components/HeaderControl';
import { SheetContent } from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading/components/SheetContent';
import { FILTERS_NAMES } from '@src/constants/filters';
import {
    AMOUNT_ERROR_MAX,
    AMOUNT_ERROR_MIN,
    AMOUNT_MAX,
    AMOUNT_MIN,
    TERM_ERROR_MAX,
    TERM_ERROR_MIN,
    TERM_MAX,
    TERM_MIN,
} from '@src/constants/fullDeal';
import { sendChangeAmountFilterEvent, sendChangeTermFilterEvent } from '@src/helpers/analyticsEvents';
import { getAmountString } from '@src/helpers/common';
import { getDaysPluralize } from '@src/helpers/strings';
import { useValidatedValue } from '@src/hooks/useValidatedValue';
import { useValidatedValueFromParams } from '@src/hooks/useValidatedValueFromParams';
import { InputSheet } from 'src/components/details/components/InputSheet';

type Props = {
    initialAmount: number;
    initialTerm: number;
    onChange: (value: any, name: string) => void;
    amountChangeAnalyticEvent?: (eventLabelPostfix?: boolean) => void;
    termChangeAnalyticEvent?: (eventLabelPostfix?: boolean) => void;
};

export type ActiveField = FILTERS_NAMES.AMOUNT | FILTERS_NAMES.TERM | null;

export const MobilePageHeading = ({
    initialAmount,
    initialTerm,
    onChange,
    amountChangeAnalyticEvent,
    termChangeAnalyticEvent,
}: Props) => {
    const [isVisible, setVisibility] = useBoolean(false);
    const [activeField, setActiveField] = useState<ActiveField>(null);

    const [amount, setAmount] = useState(initialAmount);
    const [term, setTerm] = useState(initialTerm);
    const [error, setError] = useState('');

    useValidatedValue({
        value: amount,
        min: AMOUNT_MIN,
        max: AMOUNT_MAX,
        setError,
        setValue: setAmount,
        errorMin: AMOUNT_ERROR_MIN,
        errorMax: AMOUNT_ERROR_MAX,
    });

    useValidatedValue({
        value: term,
        min: TERM_MIN,
        max: TERM_MAX,
        setError,
        setValue: setTerm,
        errorMin: TERM_ERROR_MIN,
        errorMax: TERM_ERROR_MAX,
    });

    useValidatedValueFromParams({
        onChange,
        value: initialAmount,
        min: AMOUNT_MIN,
        max: AMOUNT_MAX,
        filterName: FILTERS_NAMES.AMOUNT,
    });

    useValidatedValueFromParams({
        onChange,
        value: initialTerm,
        min: TERM_MIN,
        max: TERM_MAX,
        filterName: FILTERS_NAMES.TERM,
    });

    const amountValue = `${getAmountString(initialAmount, Currency.RUB)}`;
    const pluralizeTerm = getDaysPluralize(initialTerm);
    const termValue = `${initialTerm} ${pluralizeTerm}`;
    const sheetTitle = activeField === FILTERS_NAMES.AMOUNT ? 'Сумма займа' : 'Срок займа';
    const isAmountField = activeField === FILTERS_NAMES.AMOUNT;

    const handleSubmit = () => {
        if (isAmountField) {
            onChange(amount, FILTERS_NAMES.AMOUNT);
            amountChangeAnalyticEvent ? amountChangeAnalyticEvent() : sendChangeAmountFilterEvent(amount, 'Витрина');
        } else {
            onChange(term, FILTERS_NAMES.TERM);
            termChangeAnalyticEvent ? termChangeAnalyticEvent() : sendChangeTermFilterEvent(term, 'Витрина');
        }
        setVisibility.off();
    };

    const handleChange = useCallback(
        (value: number) => {
            isAmountField ? setAmount(value) : setTerm(value);
        },
        [isAmountField],
    );

    const handleOpenSheet = useCallback(
        (field: FILTERS_NAMES.AMOUNT | FILTERS_NAMES.TERM) => {
            setActiveField(field);
            setVisibility.on();
        },
        [setVisibility],
    );

    const handleCloseSheet = useCallback(() => {
        setActiveField(null);
        setVisibility.off();

        isAmountField ? setAmount(initialAmount) : setTerm(initialTerm);
    }, [initialAmount, initialTerm, isAmountField, setVisibility]);

    return (
        <>
            {Boolean(initialAmount) && Boolean(initialTerm) && (
                <HeaderControl amountValue={amountValue} termValue={termValue} handleOpenSheet={handleOpenSheet} />
            )}
            <InputSheet
                isButtonSubmitDisabled={Boolean(error)}
                visible={isVisible}
                onClose={handleCloseSheet}
                onSubmit={handleSubmit}
                title={sheetTitle}
            >
                <SheetContent
                    error={error}
                    term={term}
                    amount={amount}
                    activeField={activeField}
                    onChange={handleChange}
                />
            </InputSheet>
        </>
    );
};
