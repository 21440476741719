import cn from 'classnames';

import { Typography } from '@sravni/react-design-system';

import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
    text: string;
    error?: string;
};

export const HelperText = ({ text, error }: Props) => (
    <Text className={cn(styles.text, { [styles.error]: Boolean(error) })} size={12}>
        {error || text}
    </Text>
);
