import React, { SetStateAction, useEffect } from 'react';

type Props = {
    value: number;
    min: number;
    max: number;
    setError: React.Dispatch<SetStateAction<string>>;
    setValue(value: number): void;
    errorMin: string;
    errorMax: string;
};

export const useValidatedValue = ({ value, min, max, setError, setValue, errorMin, errorMax }: Props) => {
    useEffect(() => {
        if (value < min) {
            setError(errorMin);
            const timer = setTimeout(() => setValue(min), 3000);
            return () => clearTimeout(timer);
        }
        if (value > max) {
            setError(errorMax);
            const timer = setTimeout(() => setValue(max), 3000);
            return () => clearTimeout(timer);
        }
        setError('');
    }, [value, min, max, setError, setValue, errorMin, errorMax]);
};
