import type { AffSub4 } from '@src/@types/microcredits';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { usePixel } from '@src/hooks/usePixel';

const OFFER_ID = 2257;
const AFF_SUB4: AffSub4 = { organizationName: 'Фиктивный пиксель', productName: 'Сохранения номера телефона' };
const SOURCE = 'search';
const FAKE_OFFER_LINK = `https://sravni.go2cloud.org/aff_c?offer_id=${OFFER_ID}`;

export const useFakePixelClickLink = () => {
    const fakePixelClickUrl = usePixel(FAKE_OFFER_LINK, {
        source: SOURCE,
        affSub4: AFF_SUB4,
        pixelType: PIXELS_TYPES.CLICK,
    });

    const fetchFakePixelClick = async () => {
        if (!fakePixelClickUrl) return;

        try {
            await fetch(fakePixelClickUrl);
        } catch (error) {
            console.error(error);
        }
    };

    return { fetchFakePixelClick };
};
