import type { FC } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

type TMobileAdaptiveHeaderProps = Omit<Typography.HeadingProps, 'level'> & {
    size: Typography.TextProps['size'];
    mobileSize?: Typography.TextProps['size'];
};
export const MobileAdaptiveText: FC<TMobileAdaptiveHeaderProps> = ({ children, mobileSize, size, ...headerProps }) => {
    const isMobile = useIsMobile();
    let currentSize: Typography.TextProps['size'] = size;

    if (isMobile && mobileSize) {
        currentSize = mobileSize;
    }

    return (
        <Typography.Text {...headerProps} size={currentSize}>
            {children}
        </Typography.Text>
    );
};
